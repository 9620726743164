export default {
  breakpoints: ['40em', '56em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 1200,
  },
  colors: {
    white: '#FFFFFF',
    primary: '#41cea4',
    text: 'RGBA(21, 28, 43, 1.00)',
    background: '#fff',
    'success-light': '#40D9AC',
    'success-dark': '#41bb97',
    success: '#41cea4',
    'success-tinted': '#D7F5EA',
    'success-darken': '#17543E',
    error: '#E00',
    'error-light': '#FF1A1A',
    'error-dark': '#C00',
    'accent-1': '#F2F5F7',
    'accent-2': '#EAEAEA',
    'accent-3': '#F3F3F3',
    'accent-4': '#999',
    'accent-5': '#888',
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fonts: {
    body:
      'quicksand, -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue',
    heading: 'Poppins',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    default: {
      color: 'text',
      fontFamily: 'body',
      fontSize: 3,
    },
    secondary: {
      fontSize: [1, 2],
      color: 'accent-5',
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  buttons: {
    outline: {
      outline: 'none',
      color: 'text',
      bg: 'white',
      border: '1px solid',
      borderColor: 'text',
      '&:hover': {
        borderColor: 'black',
      },
    },
    primary: {
      outline: 'none',
      color: 'white',
      bg: 'primary',
      border: '1px solid',
      borderColor: 'success-dark',
      '&:hover': {
        borderColor: 'black',
      },
    },
    success: {
      outline: 'none',
      color: 'white',
      bg: 'success-light',
      border: '1px solid',
      borderColor: 'success-dark',
      '&:hover': {
        bg: 'success',
      },
    },
    error: {
      outline: 'none',
      color: 'white',
      bg: 'error-light',
      border: '1px solid',
      borderColor: 'error-dark',
      '&:hover': {
        bg: 'error',
      },
    },
    secondary: {
      color: 'background',
      bg: '#000',
    },
  },
};
